/**
 * queryToObj
 *
 * This function converts a queryString and/or hash into an Object.
 * It prioritises the end of a string over the beginning (due to pattern using "g")
 * so if the same variable is present in the query string and the hash
 * the value in the has will be the value returned.
 *
 * the params that are passed to the function are specific to the pattern
 * "sym" will be a ? # or &
 * "key" is our variable name e.g. var1 in the below example
 * "eq" is the = symbol
 * "val" is our variable value e.g. "abc" in the below example
 *
 * @param {String} str the query string / hash to decode
 * @return {Object} the decoded object of params
 *
 * @example
 *  queryToObj('?var1=abc&var2=efg#var3=123&var4=456')
 * @returns {Object} {
 *  var1: "abc",
 *  var2: "efg"
 *  var3: "123"
 *  var4: "456"
 * }
 */
export function queryToObj (str) {
  const obj = {};
  str.replace(
    new RegExp("([^?#=&]+)(=([^&#]*))?", "g"),
    (sym, key, eq, val) => {
      obj[key] = decodeURIComponent(val);
      return "";
    }
  );
  return obj;
}
