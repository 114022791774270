/* eslint-disable camelcase */
import { queryToObj } from '../util/uri';
import { mParticleLogEvent, mParticleInit, mParticleTrackSessionAttributes, detectDevice, DeviceMobile, } from '@treatwell/ui';
import get from 'lodash.get';
var mParticleInstance;
const locale = get(window, 'wahanda.pageConfig.locale.locale', '');
let countryCode;
if (locale.length) {
    countryCode = locale.split('_')[1];
}
export const initialiseMParticle = (deviceId, userIdentities) => {
    const isWidget = get(window, 'wahanda.pageConfig.isWidget');
    const environment = get(window, 'wahanda.pageConfig.environment');
    if (isWidget || !countryCode || !environment) {
        return;
    }
    const { default: mParticle } = require('@mparticle/web-sdk');
    const oneTrustKit = require('@mparticle/web-onetrust-kit');
    const options = { isDevelopmentMode: environment !== 'PROD', deviceId };
    oneTrustKit.register(options);
    mParticleInit(mParticle, options, userIdentities);
    mParticleInstance = mParticle;
    const queryParameters = queryToObj(window.location.search);
    const utmFields = [
        'utm_campaign',
        'utm_content',
        'utm_marketing',
        'utm_medium',
        'utm_source',
        'utm_term',
        'utm_campaign_id',
        'utm_ad_id',
        'gclid',
    ];
    const utmParameters = Object.fromEntries(utmFields
        .filter((field) => queryParameters[field])
        .map((field) => [field, queryParameters[field]]));
    mParticle.logPageView('page_view', {
        country: countryCode,
        referrer: document.referrer,
        url: window.location.href,
        url_path: window.location.pathname,
        query_parameters: window.location.search,
        platform: 'web',
        ...utmParameters,
    });
    mParticleTrackSessionAttributes(countryCode, utmParameters);
};
export const mParticleLogin = (data, callback) => {
    if (!mParticleInstance) {
        callback();
        return;
    }
    try {
        mParticleInstance.Identity.login(data, callback);
    }
    catch (err) {
        console.error(err);
        callback();
    }
};
export const mParticleCustomEventLogIn = (authenticationMethod) => {
    if (!mParticleInstance || !countryCode) {
        return;
    }
    mParticleLogEvent('user_logged_in', mParticleInstance.EventType.Other, {
        country: countryCode,
        platform: 'web',
        authentication_method: authenticationMethod,
        trigger_context: 'login',
        is_web_mobile: detectDevice(navigator.userAgent).type === DeviceMobile,
    });
};
export const mParticleCustomEventRegister = (authenticationMethod, hasMarketingOptedIn = false) => {
    if (!mParticleInstance || !countryCode) {
        return;
    }
    mParticleLogEvent('user_registered', mParticleInstance.EventType.UserPreference, {
        country: countryCode,
        platform: 'web',
        authentication_method: authenticationMethod,
        has_marketing_opted_in: hasMarketingOptedIn,
        trigger_context: 'login',
        is_web_mobile: detectDevice(navigator.userAgent).type === DeviceMobile
    });
};
export var mParticleGetInstance = () => mParticleInstance;
